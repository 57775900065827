@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,400;1,500;1,600&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TODO: Move to styled components */

.AboutHeading {
  font-family: inherit;
  margin-right: 0;
}

p {
  font-size: 24px;
  border: 5px solid transparent;
  position: relative;
}

.border-paragraph:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -15px;
  z-index: -1;
  transition: transform 0.6s linear;
  transform: scale(0.97, 0.93);
}

ul.hobbyList {
  position: relative !important;
  margin: 0 auto;
  clear: left;
  height: auto;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.hobbyList > li {
  padding: 1.5em 6em 0em 6em;
  list-style-type: none;
}

@media (max-width: 990px) {
  .hobbyList > li {
    padding: 2em;
  }
}

@media (max-width: 500px) {
  .hobbyList > li {
    font-size: smaller;
  }
}

@media (max-width: 445px) {
  .hobbyList > li {
    font-size: 10px;
  }
}

@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
