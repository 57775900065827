@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,400;1,500;1,600&display=swap);
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TODO: Move to styled components */

.AboutHeading {
  font-family: inherit;
  margin-right: 0;
}

p {
  font-size: 24px;
  border: 5px solid transparent;
  position: relative;
}

.border-paragraph:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -15px;
  z-index: -1;
  transition: -webkit-transform 0.6s linear;
  transition: transform 0.6s linear;
  transition: transform 0.6s linear, -webkit-transform 0.6s linear;
  -webkit-transform: scale(0.97, 0.93);
          transform: scale(0.97, 0.93);
}

ul.hobbyList {
  position: relative !important;
  margin: 0 auto;
  clear: left;
  height: auto;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.hobbyList > li {
  padding: 1.5em 6em 0em 6em;
  list-style-type: none;
}

@media (max-width: 990px) {
  .hobbyList > li {
    padding: 2em;
  }
}

@media (max-width: 500px) {
  .hobbyList > li {
    font-size: smaller;
  }
}

@media (max-width: 445px) {
  .hobbyList > li {
    font-size: 10px;
  }
}

@-webkit-keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

@keyframes blink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

html {
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(251 248 244 / 1);
  background-color: rgb(251 248 244 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(68 69 70 / 1);
  color: rgb(68 69 70 / var(--tw-text-opacity));
}

body {
  margin: 0;
  padding: 0;
  font-family: "Georgia", serif, -apple-system, "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --tw-bg-opacity: 1;
  background-color: rgb(251 248 244 / 1);
  background-color: rgb(251 248 244 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(68 69 70 / 1);
  color: rgb(68 69 70 / var(--tw-text-opacity));
}

.homeHeaders {
  display: inline-block !important;
  margin: 0 auto;
}

.projects-paragraph {
  font-size: 24px;
}

.titleContainer {
  --maskX: 0;
  --maskY: 50;
}
.titleContainer h1 {
  line-height: 1.1em;
  display: inline-block;
  text-align: center;
}

.skillItems {
  margin-top: 12%;
  margin-left: 15%;
  margin-right: 15%;
}

@media (max-width: 376px) {
  .subTitle {
    font-size: 25px;
  }

  h1 {
    margin-top: 0;
    padding-right: 5px;
  }

  .skillIcons {
    font-size: 20vw;
  }

  .row {
    flex-direction: column;
  }

  .externalLinks {
    margin-right: 0;
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .row {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .navBarHeader {
    position: none;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 4em;
    margin-top: 2em;
  }

  .subTitle {
    font-size: 2em;
  }

  span {
    font-size: 24px;
  }

  .titleWrapper {
    color: rgb(16, 135, 204);
    margin: none;
    text-align: center;
  }
}

@media (max-width: 552px) {
  h1 {
    font-size: 2.8em;
    text-align: center;
  }
  .subTitle {
    font-size: 0.75em;
  }
}

@media (max-width: 572px) {
  .externalLinks {
    clear: both;
  }
}

.subTitle {
  margin-bottom: 5%;
  text-align: center;
}

