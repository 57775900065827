html {
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(251 248 244 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(68 69 70 / var(--tw-text-opacity));
}

body {
  margin: 0;
  padding: 0;
  font-family: "Georgia", serif, -apple-system, "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --tw-bg-opacity: 1;
  background-color: rgb(251 248 244 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(68 69 70 / var(--tw-text-opacity));
}

.homeHeaders {
  display: inline-block !important;
  margin: 0 auto;
}

.projects-paragraph {
  font-size: 24px;
}

.titleContainer {
  --maskX: 0;
  --maskY: 50;
}
.titleContainer h1 {
  line-height: 1.1em;
  display: inline-block;
  text-align: center;
}

.skillItems {
  margin-top: 12%;
  margin-left: 15%;
  margin-right: 15%;
}

@media (max-width: 376px) {
  .subTitle {
    font-size: 25px;
  }

  h1 {
    margin-top: 0;
    padding-right: 5px;
  }

  .skillIcons {
    font-size: 20vw;
  }

  .row {
    flex-direction: column;
  }

  .externalLinks {
    margin-right: 0;
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .row {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .navBarHeader {
    position: none;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 4em;
    margin-top: 2em;
  }

  .subTitle {
    font-size: 2em;
  }

  span {
    font-size: 24px;
  }

  .titleWrapper {
    color: rgb(16, 135, 204);
    margin: none;
    text-align: center;
  }
}

@media (max-width: 552px) {
  h1 {
    font-size: 2.8em;
    text-align: center;
  }
  .subTitle {
    font-size: 0.75em;
  }
}

@media (max-width: 572px) {
  .externalLinks {
    clear: both;
  }
}

.subTitle {
  margin-bottom: 5%;
  text-align: center;
}
